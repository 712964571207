import {MouseEventHandler} from "react";
import InstallPWA from "../hooks/install.pwa";

export const HomePage = () => {

    return <div>
        <InstallPWA/>
        Install app
    </div>
}
