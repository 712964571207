export const getSubdomain = (): string | undefined => {
    // @ts-ignore
    const subdomain = /:\/\/([^/]+)/.exec(window.location.href)[1].split('.');

    if(subdomain.length > 2) {
        return subdomain[0];
    }

    return undefined;
}

export const getDomain = () => {
    // @ts-ignore
    const subdomain = /:\/\/([^/]+)/.exec(window.location.href)[1].split('.');

    if(subdomain.length > 2) {
        return subdomain[1];
    }

    return subdomain[0];
}
