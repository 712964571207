import {AppType} from "../../models/AppType";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

type AppStateType = AppType & {}

export const initialState: AppStateType = {
    isInitialized: false,
    subdomain: '',
    isLoading: false,
    error: '',
}

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        initialized(state, action: PayloadAction<boolean>) {
            state.isInitialized = action.payload;
        },
        setSubdomain(state, action: PayloadAction<string>) {
            state.subdomain = action.payload;
        }
    }
});

export default appSlice.reducer;
