import React, {useEffect} from 'react';
import {Routes, Route} from "react-router-dom";
import {appSlice} from "./store/reducers/AppSlice";
import {useAppDispatch, useAppSelector} from "./hooks/redux";
import {HomePage} from "./pages/HomePage";
import {AboutPage} from "./pages/AboutPage";
import {getSubdomain} from "./utilits/location";
import {Helmet} from "react-helmet";
import {getManifest} from "./utilits/manifest";
import './App.css';

function App() {
    const {isInitialized} = useAppSelector(state => state.appReducer);
    const {subdomain} = useAppSelector(state => state.appReducer);
    const {initialized, setSubdomain} = appSlice.actions;
    const manifest = getManifest(subdomain);

    const dispatch = useAppDispatch();

    useEffect(() => {
        const domain = getSubdomain();
        if(domain) {
            dispatch(setSubdomain(domain));
        }

        dispatch(initialized(true));
    }, []);

    return (
        <div className="App">
            {!isInitialized && <div>Загрузка...</div>}
            <Helmet>
                {subdomain && <link rel="manifest" href={manifest}/>}
            </Helmet>
            <Routes>
                <Route path={'/'} element={<HomePage/>}/>
                <Route path={'/about'} element={<AboutPage/>}/>
            </Routes>
        </div>
    );
}

export default App;
