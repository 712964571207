import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import * as serviceWorkerRegistration from './service/serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {setupStore} from "./store/store";
import App from './App';
import './index.css';

const store = setupStore();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
    <BrowserRouter>
        <Provider store={store}>
            <App/>
        </Provider>
    </BrowserRouter>
);

serviceWorkerRegistration.register();
reportWebVitals();
